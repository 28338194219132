import React from "react";
import { Link, PageProps } from "gatsby";
import DefaultLayout from "../../layouts/DefaultLayout";
import Image from "../../components/Image";

const Page: React.FC<PageProps> = ({ location }) => (
  <DefaultLayout pathname={location.pathname} pageTitle="Products">
    <div className="container my-3 my-lg-5 py-3">
      <div className="row">
        <div className="col-6 col-md-3">
          <Link
            className="my-3 text-decoration-none"
            to="/products/door-styles"
          >
            <Image name="door_styles" />
            <h3 className="text-center">Door Styles</h3>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link className="my-3 text-decoration-none" to="/products/benchtops">
            <Image name="benchtops" />
            <h3 className="text-center">Benchtops</h3>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link className="my-3 text-decoration-none" to="/products/joinery">
            <Image name="joinery" />
            <h3 className="text-center">Joinery</h3>
          </Link>
        </div>
        <div className="col-6 col-md-3">
          <Link
            className="my-3 text-decoration-none"
            to="/products/internal-external-doors"
          >
            <Image name="int_ext_doors" />
            <h3 className="text-center">Internal & External Doors</h3>
          </Link>
        </div>
      </div>
    </div>
  </DefaultLayout>
);

export default Page;
